import React from 'react';
import _ from 'lodash';
import { defineMessages } from '@kyruus/intl';

import { IntlReactSelect } from '../utils/intl-components';

const messages = defineMessages({
  topfilter: {
    id: 'facet.labels.topfilter',
    description: 'Label for the most popular filter',
    defaultMessage: 'Popular choices'
  },
  allfilter: {
    id: 'facet.labels.allfilter',
    description: 'Label for all other filter options',
    defaultMessage: 'All choices'
  },
  defaultplaceholder: {
    id: 'facet.typeahead.placeholder.default',
    description: 'Default placeholder text for a typeahead facet',
    defaultMessage: 'Type here...'
  },
  arialabel: {
    id: 'aria.label.facet.typeahead',
    description: 'Accessibility label for a typeahead facet',
    defaultMessage: 'Type here for filter options'
  }
});

function TypeaheadFacet({ config, facetName, addFilter, removeFilter }) {
  // We want the default text here to be sent to translators, but since the ID can't
  // be determined statically, we can't send it through the defineMessages API.
  const placeholderMessageDescriptor = {
    id: `facet.${config.field}.typeahead.placeholder`,
    defaultMessage: ''
  };
  let filters = _.map(config.terms, function (filter) {
    const labelMessageDescriptor = {
      id: `field.value.${facetName}.${filter.value}`,
      defaultMessage: filter.value,
      description: 'The label for the filter'
    };
    return {
      labelMessageDescriptor: labelMessageDescriptor,
      value: filter.filter_param,
      applied: filter.applied,
      top_filter: filter.top_filter,
      count: filter.count
    };
  });
  const applied = _.chain(filters).filter('applied').map('value').value();
  const top_filters = _.chain(filters)
    .remove('top_filter')
    .map((filter) =>
      _.pick(filter, ['labelMessageDescriptor', 'value', 'count'])
    )
    .value();
  filters = _.map(filters, (filter) =>
    _.pick(filter, ['labelMessageDescriptor', 'value', 'count'])
  );

  let optionsMessageDescriptors;
  if (top_filters.length) {
    optionsMessageDescriptors = [
      {
        labelMessageDescriptor: messages.topfilter,
        options: top_filters
      },
      {
        labelMessageDescriptor: messages.allfilter,
        options: filters
      }
    ];
  } else {
    optionsMessageDescriptors = [
      {
        options: filters
      }
    ];
  }

  const onChange = (changed) => {
    const changedValues = _.map(changed, 'value');
    if (changedValues.length > applied.length) {
      const toAdd = _.difference(changedValues, applied);
      addFilter(toAdd[0]);
    } else if (changedValues.length < applied.length) {
      const toRemove = _.difference(applied, changedValues);
      removeFilter(toRemove[0]);
    }
  };

  return (
    <div
      id={_.kebabCase(facetName)}
      data-testid={`select-area-${_.kebabCase(facetName)}`}
      className="typeahead-filter pa-s"
    >
      <IntlReactSelect
        instanceId={_.kebabCase(facetName)}
        multi={true}
        clearable={false}
        className="typeahead-facet-group"
        style={{ width: '100%' }}
        value={applied}
        placeholderMessageDescriptor={placeholderMessageDescriptor}
        defaultPlaceholderMessageDescriptor={messages.defaultplaceholder}
        optionsMessageDescriptors={optionsMessageDescriptors}
        ariaMessageDescriptor={messages.arialabel}
        onChange={onChange}
        optionRenderer={(item) => {
          return (
            <>
              {item.label}
              {item.count ? (
                <span className="count"> ({item.count})</span>
              ) : null}
            </>
          );
        }}
      />
    </div>
  );
}

export default TypeaheadFacet;
