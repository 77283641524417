import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { fromTheme } from '@kyruus/ui-theme';
import Button from '@kyruus/button';

import zIndex from '../styles/z-index-constants';
import PoweredByKyruusLogo from '../shared/powered-by-logo';
import SearchPagination from './pagination';

export const StyledChipsContainer = styled.div`
  display: flex;
  gap: ${fromTheme('spacing_medium')} ${fromTheme('spacing_small')};
  flex-wrap: wrap;
  margin-bottom: ${fromTheme('spacing_medium')};
  line-height: ${fromTheme('font_line_height')};
`;

export const AvailabilityControlsContainer = styled.div`
  margin-bottom: ${fromTheme('spacing_medium')};
`;

export const LoadingSearchWidget = styled.div`
  opacity: 0.1;
  min-height: 212px;
  @media (min-width: ${fromTheme('screen_medium')}) {
    min-height: 136px;
  }
`;

export const MobileViewResultWrapper = styled.div`
  position: absolute;
  z-index: 1;
`;

export const MobileViewResultContainer = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  bottom: 40px;
  width: 160px;
  margin: 0 auto;
  display: block;

  @media only screen and (min-width: ${fromTheme('screen_medium')}) {
    display: none;
  }
`;

export const MobileViewResultBtn = styled.button`
  background-color: ${fromTheme('color_button')};
  color: ${fromTheme('color_text_contrast')};
`;

export const StyledButtonTopPanel = styled('div')`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const StyledFacetsFixedTop = styled('div')`
  border-bottom: 1px solid ${fromTheme('color_border')};
  height: ${fromTheme('spacing_large')};
  width: 100%;
  padding: ${fromTheme('spacing_medium')} ${fromTheme('spacing_medium')}
    ${fromTheme('spacing_large')};
  overflow: hidden;
  background: ${fromTheme('color_background')};
  @media (max-width: 992px) {
    position: fixed;
    top: 0;
    width: 100%;
  }
`;

export const StyledCloseButton = styled(Button)`
  padding: 0;
  color: #087db9;
  float: right;
  &:hover {
    background-color: transparent;
    border-color: transparent;
  }
`;

export const ClearFilterLink = styled(Link)`
  color: ${fromTheme('color_link')};
  margin-left: auto;
  font-size: ${fromTheme('font_size')};

  &:hover {
    background: none;
    text-decoration: underline;
  }

  position: absolute;
  right: 0;
  bottom: ${fromTheme('spacing_small')};
  padding: 0 ${fromTheme('spacing_medium')} ${fromTheme('spacing_xsmall')} 0;
`;

const facetPanelMobileStyle = (props) => css`
  /* iOS Safari: This enables momentum scrolling KENG-3993 */
  -webkit-overflow-scrolling: ${props.showMobileFacets ? `touch` : `auto`};
  display: ${props.showMobileFacets ? `block` : `none`};
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  background: white;
  z-index: ${zIndex.dropdown};
  padding: 50px 0 70px;

  .filter-group {
    max-width: 100%;
  }

  select {
    padding: 5px;
  }
`;

export const StyledFacetPanel = styled('div')`
  margin-bottom: 150px;
  ${(props) => props.showMobileFacets && facetPanelMobileStyle};
`;

export const StyledFacetListHeader = styled('h1')`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

export const StyledFacetListHeaderWithMobileFacets = styled('h1')`
  font-size: ${fromTheme('font_size_heading_4')};
  margin-top: ${fromTheme('spacing_medium')};
  margin-bottom: 10px;
`;

export const StyledCrmWarning = styled('div')`
  margin-top: ${fromTheme('spacing_medium')};
  margin-bottom: ${fromTheme('spacing_medium')};
`;

export const StyledLegend = styled('legend')`
  font-size: ${fromTheme('font_size_heading_5')};
  font-weight: ${fromTheme('font_weight_bold')};
  position: absolute;
  top: ${fromTheme('spacing_medium')};
  left: ${fromTheme('spacing_medium')};
`;

export const FacetWrapper = styled('div')`
  padding-top: ${fromTheme('spacing_medium')};
`;

export const Footer = styled.div`
  // KENG-28970
  // Adding some extra margin to the footer when crm is enabled
  // and referral list drawer is open
  margin-bottom: ${(props) => () =>
    props.hasReferrals ? `300px;` : fromTheme('spacing_xxlarge')};

  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${fromTheme('spacing_xlarge')};
  grid-template-areas: ${({ showPagination, showDisclaimer }) => {
    let template = '';
    if (showPagination) {
      template += `'pagination'`;
    }
    if (showDisclaimer) {
      template += `'disclaimer'`;
    }
    template += `'poweredby'`;
    return template;
  }};

  button {
    background: transparent;
  }

  @media only screen and (min-width: ${fromTheme('screen_medium')}) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ${({ showPagination, showDisclaimer }) => {
      let template = '';
      if (showPagination) {
        if (showDisclaimer) {
          template = `'pagination poweredby' 'disclaimer disclaimer'`;
        } else {
          template = `'pagination poweredby'`;
        }
      } else {
        if (showDisclaimer) {
          template = `'disclaimer poweredby'`;
        } else {
          template = `'poweredby poweredby'`;
        }
      }
      return template;
    }};
  }
`;

export const Pagination = styled(SearchPagination)`
  grid-area: pagination;
  margin-bottom: ${fromTheme('spacing_xlarge')};

  @media print {
    visibility: hidden;
  }
`;

export const PoweredByLogo = styled(PoweredByKyruusLogo)`
  grid-area: poweredby;
`;

export const Disclaimer = styled.div`
  grid-area: disclaimer;
  font-size: ${fromTheme('font_size_small')};
  color: ${fromTheme('color_text_secondary')};
  text-align: center;
  align-self: center;

  @media only screen and (min-width: ${fromTheme('screen_medium')}) {
    text-align: left;
  }
`;
